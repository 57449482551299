import React from 'react';
import { graphql, PageProps } from 'gatsby';
import SEO from '../components/SEO';
import OpenAccount from '../components/Registration/OpenAccount';

const PAGE_TITLE = 'Abre una cuenta';
const PAGE_DESCRIPTION = 'Abre una cuenta de persona o empresa';

const OpenAccountPage: React.FC<PageProps> = () => {
  return (
    <>
      <SEO title={PAGE_TITLE} description={PAGE_DESCRIPTION} />
      <OpenAccount />
    </>
  );
};

export default OpenAccountPage;

export const pageQuery = graphql`
  query OpenAccount {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
