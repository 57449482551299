import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  openAccountContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '120px',
    minHeight: '500px',
    paddingBottom: '60px',
  },
  title: {
    color: '#004F71',
    marginBottom: '40px',
  },
  subtitle: {
    '& b': {
      color: `#F8B133`,
      textDecoration: `underline`,
      marginLeft: 2,
    },
    '& p': {
      float: `left`,
      textAlign: `center`,
      marginTop: `30px`,
      color: '#004F71',
      marginBottom: `45px`,
      [theme.breakpoints.down(501)]: {
        marginTop: `9px`,
        marginBottom: '31px',
      },
    },
    margin: `auto`,
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
    gap: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  card: {
    width: 280,
    height: 300,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: theme.shadows[4],
    },
  },
  personalAccountCard: {
    backgroundColor: '#e6edf2',
  },
  businessAccountCard: {
    backgroundColor: '#f0f0f0',
  },
  iconCircle: {
    backgroundColor: '#FFF',
    borderRadius: '50%',
    width: 130,
    height: 130,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
  },
  personalIcon: {
    fontSize: 80,
    color: '#094a6a',
  },
  businessIcon: {
    fontSize: 80,
    color: '#68696a',
  },
  text: {
    marginTop: theme.spacing(2),
    fontWeight: 'bold',
  },
  personalText: {
    color: '#094a6a',
  },
  businessText: {
    color: '#68696a',
  },
}));

export default useStyles;
