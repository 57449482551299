import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'gatsby';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import { Grid, AppBar, Toolbar, Avatar, Box } from '@material-ui/core';
import NavbarLinks from './NavbarLinks';
import NavItemMobile from './NavItemMobile';
import logo2 from '../../images/logo_bird.png';
import logo from '../../images/logo_navbar.svg';
import useStyles from './Navbar.styles';

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    open?: boolean;
  }
}

const NavBar = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState<boolean>(false);

  const handleDrawerOpen = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  return (
    <>
      <div className={classes.row}>
        <AppBar position="fixed" color="default" className={classes.AppBar}>
          <Grid item xs className={classes.container}>
            <Toolbar className={classes.toolbar}>
              <Grid item xs={6} sm={4} md={2} className={classes.growImage}>
                <div className={classes.mainLogo}>
                  <Avatar
                    src={logo}
                    alt="Logo"
                    to="/"
                    component={Link}
                    className={classes.avatar}
                  />
                </div>
              </Grid>
            </Toolbar>
          </Grid>
        </AppBar>
      </div>
    </>
  );
};

export default NavBar;
