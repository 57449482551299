import React from 'react';
import { Container, Grid, Typography, Card, CardContent } from '@material-ui/core';
import NavBarV2 from '../Header/NavBarV2';
import PersonIcon from '@material-ui/icons/Person';
import BusinessIcon from '@material-ui/icons/Business';
import useStyles from './OpenAccount.styles';
import { Link } from 'gatsby';

const OpenAccount: React.FC = () => {
  const classes = useStyles();

  return (
    <Container className={classes.openAccountContainer}>
      <NavBarV2 />
      <Typography variant="h5" className={classes.title}>
        ¿Cómo quieres registrarte?
      </Typography>
      <Grid container className={classes.cardContainer}>
        <Grid item>
          <a href="/register" className={`${classes.card} ${classes.personalAccountCard}`}>
            <CardContent>
              <div className={classes.iconCircle}>
                <PersonIcon className={classes.personalIcon} />
              </div>
              <Typography variant="body1" className={`${classes.text} ${classes.personalText}`}>
                Cuenta Personal
              </Typography>
            </CardContent>
          </a>
        </Grid>
        <Grid item>
          <a
            href={`${process.env.GATSBY_URL_NEW_FRONT}/forms/createCompany/basic-information`}
            className={`${classes.card} ${classes.businessAccountCard}`}
          >
            <CardContent>
              <div className={classes.iconCircle}>
                <BusinessIcon className={classes.businessIcon} />
              </div>
              <Typography variant="body1" className={`${classes.text} ${classes.businessText}`}>
                Cuenta Empresa
              </Typography>
            </CardContent>
          </a>
        </Grid>
      </Grid>
      <Grid className={classes.subtitle}>
        <p>¿Ya estás registrado? </p>{' '}
        <Link to={`${process.env.GATSBY_URL_NEW_FRONT}/login`}>
          {' '}
          <p>
            <b> Inicia sesión</b>
          </p>
        </Link>
      </Grid>
    </Container>
  );
};

export default OpenAccount;
